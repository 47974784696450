import React from 'react';

import styles from "../../Assests/css/centro/desktop/centernetworks.module.scss"
class Centernetworks extends React.Component{
    render(){
        return(
            <div className={styles.estiloCentro}>   
                estas son las redes
            </div>
        );
    
    }
}
export default Centernetworks;