import React from 'react';

import styles from "../../Assests/css/centro/desktop/centerredeem.module.scss"
class Centerredeem extends React.Component{
    render(){
        return(
            <div className={styles.estiloCentro}>   
                Centro de Redimir
            </div>
        );
    
    }
}
export default Centerredeem;