import React from 'react';

import styles from "../../Assests/css/centro/desktop/centerstatistics.module.scss"

class CenterStatistics extends React.Component{
    render(){
        return(
            <div className={styles.estiloCentro}>   
                Centro de estadisticas
            </div>
        );
    
    }
}
export default CenterStatistics;