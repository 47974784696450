import React from 'react';

import styles from "../../Assests/css/centro/desktop/centerfollow.module.scss"
class Centercontactus extends React.Component{
    render(){
        return(
            <div className={styles.estiloCentro}>   
                centro contactanos
            </div>
        );
    
    }
}
export default Centercontactus;