import React from 'react';

import styles from "../../Assests/css/centro/desktop/centerawardpoints.module.scss"
class Centerawardpoints extends React.Component{
    render(){
        return(
            <div className={styles.estiloCentro}>   
                Otorgar puntos
            </div>
        );
    
    }
}
export default Centerawardpoints;