import React from 'react';

import styles from "../../Assests/css/centro/desktop/centermyplan.module.scss"
class Centermyplan extends React.Component{
    render(){
        return(
            <div className={styles.estiloCentro}>   
                Centro de planes
            </div>
        );
    
    }
}
export default Centermyplan;