import React from 'react';

import styles from "../../Assests/css/centro/desktop/centerproceeds.module.scss"
class Centerproceeds extends React.Component{
    render(){
        return(
            <div className={styles.estiloCentro}>   
                Beneficios
            </div>
        );
    
    }
}
export default Centerproceeds;