import React from 'react';
/*import styles from "../../../src/Assests/css/pages/login/logintitle.module.scss";*/


class ForgotPassword extends React.Component{
    render(){
        return(
            <p>¿Olvidaste la contraseña?</p>
        );
    }
}
export default ForgotPassword;