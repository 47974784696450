import React from 'react';
import styles from "../../../Assests/css/pages/verifyemail/verifyemailtitle.module.scss";


class VerifyEmailTitle extends React.Component{
    render(){
        return(
            <span className={styles.titleverifyemail}>Verifica tu correo electrónico</span>
        );
    }
}
export default VerifyEmailTitle;