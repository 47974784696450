import React from 'react';

import styles from "../../../src/Assests/css/pages/forgotpasswordpage/forgotpasswordpage.module.scss"

class ForgotPasswordPage extends React.Component{
    render(){
        return(
            <p>Página de olvido su contraseña</p>
        );
    }
}
export default ForgotPasswordPage; 