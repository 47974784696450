import React from 'react';

import styles from "../../Assests/css/centro/desktop/centercompanyprofile.module.scss"
class Centercompanyprofile extends React.Component{
    render(){
        return(
            <div className={styles.estiloCentro}>   
                Perfil de empresa
            </div>
        );
    
    }
}
export default Centercompanyprofile;