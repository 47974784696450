import React from 'react';
/*import styles from "../../../src/Assests/css/pages/login/logintitle.module.scss";*/


class TermsConditionsPage extends React.Component{
    render(){
        return(
            <p>aqui van los terminos y condiciones</p>
        );
    }
}
export default TermsConditionsPage;