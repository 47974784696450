import React from 'react';

import styles from "../../Assests/css/centro/desktop/centerprofile.module.scss"
class Centerprofile extends React.Component{
    render(){
        return(
            <div className={styles.estiloCentro}>   
                estas es el perfil
            </div>
        );
    
    }
}
export default Centerprofile;