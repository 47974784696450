import React from 'react';

import styles from "../../Assests/css/centro/desktop/centerfollow.module.scss"
class Centerfollow extends React.Component{
    render(){
        return(
            <div className={styles.estiloCentro}>   
                siguenos Fiel APP!!!
            </div>
        );
    
    }
}
export default Centerfollow;